body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.RaLayout-content {
  padding-right: 8px !important;
}

@font-face {
  font-family: 'Gilroy';
  src:
    local('Gilroy'),
    url(../public/fonts/Gilroy/Gilroy-Medium.ttf) format('truetype');
}
